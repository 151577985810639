<template>
  <div>
      <h1>Цепочки заказов</h1>
  </div>
</template>

<script>
export default {
  name: 'OrderChain'
}
</script>
